<div
	layout="column"
	layout-fill
	flex
	overflow-auto-y
	class="sidenav-asset-ticket-container"
>
	<ng-template #lazyLoadContainer></ng-template>
	<!-- <router-outlet name="assetticketrouteroutlet"></router-outlet> -->
</div>
