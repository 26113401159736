import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ServiceOrderPlanningSideNavAssetTicketService } from './sidenav-asset-ticket.service';
import { APIServiceOrderPlanningGETItem, APIServiceOrderPlanningGETItemOpenedTickets } from 'src/app/api-services/portal/service-order-planning.interface';
import { AutoUnsubscribeOnDestroy, autoUnsubscribe, getObservableAndAutoUnsubscribe } from 'src/app/helpers/auto-unsubscribe';
import { Subscription } from 'rxjs';
import { ServiceOrderPlanningSideNavAssetTicketRouteService } from './route/route.service';
import { DataItem } from '../../datasource/item.interface';
import { SidenavBaseComponent } from 'src/app/helpers/sidenav-base.component';
import { ActivatedRoute } from '@angular/router';
import { RoutesService } from 'src/app/routes/routes.service';

@Component({
	selector: 'sidenav-asset-ticket',
	templateUrl: './sidenav-asset-ticket.component.html',
	styleUrls: ['./sidenav-asset-ticket.component.scss']
})
export class ServiceOrderPlanningSideNavAssetTicketComponent
	extends SidenavBaseComponent
	implements OnInit, OnDestroy, AutoUnsubscribeOnDestroy {

	obs: Subscription[] = [];

	serial: APIServiceOrderPlanningGETItem = null;
	ticket: APIServiceOrderPlanningGETItemOpenedTickets = null;

	@ViewChild("lazyLoadContainer", { read: ViewContainerRef }) lazyLoadContainer!: ViewContainerRef;

	@Input({required: true}) ticketId: string;

	@Output() updateDataItem = new EventEmitter<DataItem>();
	@Output() updateTicket = new EventEmitter<string>();

	constructor(
		private activatedRoute: ActivatedRoute,
		private routesService: RoutesService,
		private assetTicketRouteService: ServiceOrderPlanningSideNavAssetTicketRouteService,
		private serviceOrderPlanningSideNavAssetTicketService: ServiceOrderPlanningSideNavAssetTicketService,
	) {
		super();
	}

	ngOnInit(){
		if(this.activatedRoute.snapshot.params?.ticketId){
			this.ticketId = this.activatedRoute.snapshot.params?.ticketId;
		}
		this.serviceOrderPlanningSideNavAssetTicketService.matSidenav = this.sideNavRight;
		this.onTicketFinishUpdate();
		this.lazyLoad();
	}

	async lazyLoad(){

		const { ServiceOrderPlanningSideNavAssetTicketRouteComponent } = await import("./route/route.component");

		this.lazyLoadContainer.clear();

		const container = this.lazyLoadContainer.createComponent(
			ServiceOrderPlanningSideNavAssetTicketRouteComponent
		);

		container.instance.state = {
			ticketId: this.ticketId
		}

	}

	onTicketFinishUpdate(){
		getObservableAndAutoUnsubscribe(
			this,
			this.assetTicketRouteService.ticketFinishUpdate.subscribe(ticket => {
				this.updateDataItem.next(null)
				this.updateTicket.emit(ticket.ticket_id)
			})
		);
	}

	ngOnDestroy(): void {
		this.serviceOrderPlanningSideNavAssetTicketService.matSidenav = null;
		super.ngOnDestroy();
		console.log('sidenav ngOnDestroy');
		autoUnsubscribe(this);
	}

}
